import React,{Component} from  'react';
import moment from 'moment';
import PropTypes from 'prop-types';
import {EmptyDay} from './Day';

import {actionCreators as plannedItemActions} from '../../store/PlannedItemStore';


const _MS_PER_DAY = 1000 * 60 * 60 * 24;

class CalendarLine extends Component{

    shouldComponentUpdate(nextProps, nextState, nextContext) {
        const plansDifferent = JSON.stringify(this.props.plans) !== JSON.stringify(nextProps.plans);
        const eventsDifferent = JSON.stringify(this.props.events) !== JSON.stringify(nextProps.events);
        const daysDifferent = this.props.days[0] !== nextProps.days[0] ||
            this.props.days.length !== nextProps.days.length;
        const wasHighLighted = !!(this.props.person.id === this.props.highLightPersonId);
        const isHighLighted = !!(nextProps.person.id === nextProps.highLightPersonId);

        return plansDifferent || daysDifferent || (wasHighLighted !== isHighLighted) || eventsDifferent;
    }

  


    render(){
        const props = this.props;
        const person = props.person;
        const highLightId = props.highLightPersonId;
        const granularity = props.granularity;
        const days = props.days;
        const dayWidth = props.dayWidth;
        const plans = props.plans; // et array af denne persons planer
        const eventColor = props.eventColor;
        const events = props.events.filter(x => x.invitations.find(y => y.status === 1 && y.personId === person.id) != null);
        // events bliver pushet til planarrayet
        events.forEach(e => {
            plans.push({startTime: e.startTime, endTime: e.endTime, colorCode: eventColor});
        });
        
        const hourComponents = days.map((d,i) => {
            return plans.map((p,i) => {
             
                const startTime = moment(p.startTime).diff(props.startTime, 'hour') * dayWidth;
                const duration = moment(p.endTime).diff(p.startTime, 'hour');

                if(duration > 0){
                    return <div key={`${p.id}-${i}-1`}>
                        <div className={`plan `} style={{left: `${startTime}%`, backgroundColor: p.colorCode.hexColor, width: `${dayWidth*duration}%`}}/>
                    </div>
                }
                return null;
            });
        });
        
        const dayComponents = () => {
            const start = new Date(props.startTime);
            return plans.map((p,i) => {
                let planStart = new Date(new Date(p.startTime));
                const isSick = p.colorCode.systemName == 'sick';
                let planEnd = new Date(p.endTime);
                if(isSick){
                    const fromPlan = moment(p.endTime);
                    const start = moment(p.startTime);
                    const now = moment();
                    const nowadd3days = moment().add(1, 'days');
                    planEnd = fromPlan < nowadd3days ? fromPlan : nowadd3days;
                    planStart = start > now ? start : now;
                }

                const startTime = Math.ceil(((planStart - start) / _MS_PER_DAY) - 1) * dayWidth;
                const duration = Math.ceil((planEnd - planStart) / _MS_PER_DAY);
                const isEvent = p.colorCode === props.eventColor;

                if(duration > 0){
                    return <div key={`${p.id}-${i}-2`}>
                        <div className={`plan ${isEvent ? 'event' : ''} day`} style={{left: `${startTime}%`, backgroundColor: p.colorCode.hexColor, width: `${dayWidth*duration}%`}}/>
                    </div>
                }
                
                
                return null;
            });
        };

        const grid = days.map((d,i) => {
            return <EmptyDay key={i} size={dayWidth} highlight={person.id === highLightId}/>;
        });

    

        return (
            <div className="row" onClick={props.onClick || null}>
                <div className={`name${person.leader ? ' leader' : ''}`}>{person.name}</div>
                <div className='plans'>
                    {granularity === 'hour' ? hourComponents : dayComponents()}
                </div>
                <div className='cell-grid'>
                    {grid}
                </div>
            </div>
        );
    };
}

CalendarLine.propTypes = {
    granularity: PropTypes.string.isRequired,
    highLightPersonId: PropTypes.string,
    onClick: PropTypes.func,
    startTime: PropTypes.any.isRequired,
    totalTime: PropTypes.number.isRequired
};

export default CalendarLine;