import React from 'react';
import './Carousel.css';
import { connect } from 'react-redux';
import {bindActionCreators} from 'redux';
import { useEffect } from 'react';
import { useState } from 'react';
import CustomTeamList from '../organization/CustomTeamList';
import {actionCreators as frontpageActions} from '../../store/FrontpageStore';



const Carousel = (props) => {

    const currentIndex = props.frontpageStore.activeFrontpage;
    const [currentPadding, setCurrentPadding] = useState(props.frontpageStore.activeFrontpage);

    const teamsFirstPage = [
        {
            id: 75
        },
        {
            id: 76
        },
        {
            id: 77
        },
        {
            id: 98//78 fordi de kom til at slette teamet og derefter har oprettet det igen
        },
    ];

    const teamsSecondPage = [
        {
            id: 79,
            width: 25
        },
        {
            id: 123,
            width: 25
        },
        {
            id: 80,
            width: 25
        },
        {
            id: 81,
            width: 25
        },
    ];

    const children = [<CustomTeamList key={1} padding={currentPadding} teams={teamsFirstPage}/>,<CustomTeamList key={2} padding={currentPadding} teams={teamsSecondPage}/>];

    const [length, setLength] = useState(children.length);

    // Set the length to match current children from props
    useEffect(() => {
        setLength(children.length)
    }, [children]);


    const next = () => {
        if (currentIndex < (length - 1)) {
            props.frontpageActions.setActiveFrontpage(1);
            setCurrentPadding(prevState => prevState + 5);
        }
    }
    
    const prev = () => {
        if (currentIndex > 0) {
            props.frontpageActions.setActiveFrontpage(-1);
            setCurrentPadding(prevState => prevState - 5);
        }
    }

    const [touchPosition, setTouchPosition] = useState(null);

    const handleTouchStart = (e) => {
        const touchDown = e.touches[0].clientX;
        setTouchPosition(touchDown);
    }

    const handleTouchMove = (e) => {
        const touchDown = touchPosition
    
        if(touchDown === null) {
            return
        }
    
        const currentTouch = e.touches[0].clientX
        const diff = touchDown - currentTouch
    
        if (diff > 5) {
            next()
        }
    
        if (diff < -5) {
            prev()
        }
    
        setTouchPosition(null)
    }

    let hideLeftArrow = currentIndex == 0 ? 'hidden' : 'visible'; // hvis den står på den første
    let hideRightArrow = currentIndex == length - 1 ? 'hidden' : 'visible'; // hvis den står på det sidste child
    
    return (
      
        <div className='carousel-container'>
            <div className='carousel-wrapper'>

                    <div className="left-arrow" onClick={prev} style={{visibility: hideLeftArrow}}>
                        &#8249;
                    </div>

                    <div className='carousel-content-wrapper' onTouchStart={handleTouchStart} onTouchMove={handleTouchMove}>
                        <div className="carousel-content" style={{ transform: `translateX(-${currentIndex * 100}%)` }}>
                            {children}
                        </div>
                    </div>

                    <div className="right-arrow" onClick={next} style={{visibility: hideRightArrow}}>
                        &#8250;
                    </div>
            </div>
        </div>
    )

}

//export default Carousel

export default connect(
    state => ({
        frontpageStore: state.frontpageStore,
    }),
    dispatch => ({
        frontpageActions: bindActionCreators(frontpageActions, dispatch)
    })
)(Carousel);