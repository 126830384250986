import React, {Component} from 'react';
import {Route, Switch} from 'react-router';
import Home from './Home';
import Plan from './Plan';
import connect from 'react-redux/es/connect/connect';
import {bindActionCreators} from 'redux';
import {actionCreators as routerActions} from './store/RouterActions';
import {actionCreators as winterActions} from './store/WinterDutyStore';
import {actionCreators as personActions} from './store/PersonStore';
import {actionCreators as plannedItemActions} from './store/PlannedItemStore';
import {actionCreators as teamActions} from './store/TeamStore';
import {actionCreators as slideActions} from './store/SlideStore';
import {actionCreators as tv2NewsActions} from './store/Tv2NewsStore';
import * as DocumentStore from './store/DocumentStore';
import * as StaticMapStore from './store/StaticMapStore';
import * as GridStore from './store/GridStore';
import * as AppStore from './store/AppStore';
import Documents from './components/Documents';
import StaticMap from './components/StaticMap';
import {Danish} from 'flatpickr/dist/l10n/da';
import * as flatpickr from 'flatpickr';
import IdleTimer from 'react-idle-timer';
import Login from './components/Login';
import Resource from './components/resource/Resource';
import EventOverview from './components/event/EventOverview';
import * as FrontpageStore from './store/FrontpageStore';


document.addEventListener('contextmenu', event => event.preventDefault());

Date.prototype.getWeek = function () { // eslint-disable-line no-extend-native
    var onejan = new Date(this.getFullYear(), 0, 1);
    return Math.ceil((((this - onejan) / 86400000) + onejan.getDay() + 1) / 7);
};

class App extends Component {

    componentDidMount(){
        flatpickr.localize(Danish);

        this.props.appActions.refresh();
        this.props.appActions.refreshSlow();

        setInterval(() => {
            this.props.appActions.refresh();
        }, 1000*60*2);
        setInterval(() => {
            this.props.appActions.refreshSlow();
        }, 1000*60*15);
    }

    // <Route exact path='/map' component={StaticMap}/>
    render(){
        return(
            <div>
                <Switch>
                    <Route exact path="/" component={Home} />
                    <Route exact path="/plan/:personId?" component={Plan} />
                    <Route exact path="/documents" component={Documents}/>
                    <Route exact path='/login' component={Login}/>
                    <Route exact path='/resource' component={Resource}/>
                    <Route exact path='/events' component={EventOverview}/>
                </Switch>
                <IdleTimer
                    ref="idleTimer"
                    element={document}
                    idleAction={() => this.props.routerActions.navigate('/')}
                    timeout={60000} />
            </div>
        );
    }
}

export default connect(
    state => ({state: state}),
    dispatch => ({
        routerActions: bindActionCreators(routerActions, dispatch),
        winterActions: bindActionCreators(winterActions, dispatch),
        personActions: bindActionCreators(personActions, dispatch),
        plannedItemActions: bindActionCreators(plannedItemActions, dispatch),
        teamActions: bindActionCreators(teamActions, dispatch),
        slideActions: bindActionCreators(slideActions, dispatch),
        tv2NewsActions: bindActionCreators(tv2NewsActions, dispatch),
        gridActions: bindActionCreators(GridStore.actionCreators, dispatch),
        documentActions: bindActionCreators(DocumentStore.actionCreators, dispatch),
        frontpageActions: bindActionCreators(FrontpageStore.actionCreators, dispatch),
        staticMapActions: bindActionCreators(StaticMapStore.actionCreators, dispatch),
        appActions: bindActionCreators(AppStore.actionCreators, dispatch),
    })
)(App);
